import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Profile } from "../../../types/platform/Profile";
import { getPublicProfile } from "../../../util/ApiIntegration";
import Gravatar from "../Gravatar";
import { Loading } from "../Loading";

export interface ProfileBadgeProperties {
  id: string;
  email?: string;
  name?: string;
  loadedProfile?: (profile: Profile) => void;
}

export const ProfileBadge = (
  properties: ProfileBadgeProperties,
): JSX.Element => {
  const { id, email, name, loadedProfile } = properties;

  const { data: profile, isLoading } = useQuery({
    queryKey: [`getPublicProfile-${id}`],
    queryFn: async () => {
      const responseData = await getPublicProfile(id);

      if (!profile) {
        if (name) {
          responseData.name = name;
        }
        if (email) {
          responseData.email = email;
        }
      }

      if (!responseData.name && responseData.email) {
        responseData.name = responseData.email.split("@")[0];
      }

      return responseData;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (profile && loadedProfile) {
      loadedProfile(profile);
    }
  }, [profile]);

  return (
    <div>
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <Gravatar
            className="relative inline-block h-10 w-10 rounded-full ring-2 ring-white"
            hash={id}
            title={profile?.name || profile?.email || profile?.id}
          />
        </div>
        <div className="ml-2">
          <div className="text-sm">
            {isLoading && (
              <div className="w-44">
                <Loading type="skeleton"></Loading>
              </div>
            )}
            {profile && (
              <>
                <p className="text-lg leading-6 font-medium">{profile.name}</p>
                <p className="text-sm text-gray-600">{profile.email}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
