import { clarity } from "clarity-js";

export const initializeClarity = () => {
  if (typeof window !== "undefined") {
    clarity.start({
      projectId: process.env.REACT_APP_TOOLKIT_CLARITY_ID,
      content: true, // Optional: Enable content capture
      lean: false, // Optional: Disable this for full data capture
    });
  }
};

export const identifyUserInClarity = (id: string) => {
  if (typeof window !== "undefined" && id) {
    clarity.identify(id);
  }
};

export const setCustomTagInClarity = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    clarity.set(key, value);
  }
};

export const logCustomEventInClarity = (name: string, value: string) => {
  if (typeof window !== "undefined") {
    clarity.event(name, value);
  }
};
