import React, { useRef, useCallback } from "react";
import { useField } from "formik";
import { debounce } from "lodash";
import clsx from "clsx";

interface TextInputFieldProperties {
  name: string;
  label?: string;
  rows?: number;
  helper?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  onFinishedInput?: (value: string) => void;
  showError?: boolean;
  debounceTime?: number;
  className?: string;
}

export const TextInputField: React.FC<TextInputFieldProperties> = ({
  name,
  label,
  rows = 1,
  helper,
  placeholder,
  required = false,
  disabled = false,
  error,
  onChange,
  onFinishedInput,
  showError = true,
  debounceTime = 300,
  className,
}) => {
  const [field, meta] = useField(name);
  const inputReference = useRef<HTMLInputElement | HTMLTextAreaElement | null>(
    null,
  );

  const debouncedOnFinishedInput = useRef(
    debounce((value: string) => {
      if (onFinishedInput) onFinishedInput(value);
    }, debounceTime),
  ).current;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onChange(event);
      if (onChange) onChange(event.target.value);
    },
    [field, onChange],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur(event);
      debouncedOnFinishedInput(event.target.value);
    },
    [field, debouncedOnFinishedInput],
  );

  const InputComponent = rows > 1 ? "textarea" : "input";

  const inputClassName = clsx(
    "text-toolkitBlack shadow focus:ring-toolkitTurquoise focus:border-toolkitTurquoise block w-full disabled:bg-gray-100 border-gray-300 rounded-md",
    {
      "resize-y min-h-fit": rows > 1,
    },
    className,
  );

  const referenceCallback = useCallback(
    (element: HTMLInputElement | HTMLTextAreaElement | null) => {
      inputReference.current = element;
    },
    [],
  );

  return (
    <div>
      {label && (
        <label htmlFor={`${name}-input`} className="block font-medium mb-1">
          {label}
          {required && <span className="text-coachPink ml-1">*</span>}
        </label>
      )}
      <InputComponent
        {...field}
        ref={referenceCallback}
        id={`${name}-input`}
        rows={rows > 1 ? rows : undefined}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        className={inputClassName}
        aria-invalid={!!error}
        aria-describedby={
          `${helper ? `${name}-helper` : ""} ${
            showError && error ? `${name}-error` : ""
          }`.trim() || undefined
        }
      />
      {helper && (
        <p id={`${name}-helper`} className="mt-2 text-sm text-gray-600">
          {helper}
        </p>
      )}
      {showError && meta.touched && meta.error && (
        <p id={`${name}-error`} className="mt-2 text-sm text-coachPink">
          {meta.error}
        </p>
      )}
    </div>
  );
};
