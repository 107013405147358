import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./util/AppInsights";
import { AppRoutes } from "./util/AppRoutes";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/layouts/navigation/ScrollToTop";
import { ProfileProvider } from "./util/ProfileContext";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./util/msal";
import { MsalProvider } from "@azure/msal-react";

export const TeacherToolkitAppBaseUrl =
  process.env.REACT_APP_TOOLKIT_APP_BASE || "";

export const MSALInstance = new PublicClientApplication(msalConfig);

function App(): JSX.Element {
  const queryClient = new QueryClient();

  const insightsKey =
    process.env.REACT_APP_TOOLKIT_APP_INSIGHTS_INSTRUMENTATION;
  if (!insightsKey) {
    throw new Error("Unable to determine app insights configuration");
  }

  const googleAnalyticsKey =
    process.env.REACT_APP_TOOLKIT_GOOGLEANALYTICS_MEASUREMENTID;
  if (!googleAnalyticsKey) {
    throw new Error("Unable to determine google analytics configuration");
  }

  ReactGA.initialize(googleAnalyticsKey);

  return (
    <MsalProvider instance={MSALInstance}>
      <ProfileProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ScrollToTop />
              <AppRoutes />
            </QueryClientProvider>
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </ProfileProvider>
    </MsalProvider>
  );
}

export default App;
