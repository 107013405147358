import { ListItem } from "../types/platform/ListItem";
import AR from "../images/flags/AR.svg";
import AU from "../images/flags/AU.svg";
import AT from "../images/flags/AT.svg";
import BE from "../images/flags/BE.svg";
import BR from "../images/flags/BR.svg";
import CA from "../images/flags/CA.svg";
import CL from "../images/flags/CL.svg";
import DK from "../images/flags/DK.svg";
import FI from "../images/flags/FI.svg";
import FR from "../images/flags/FR.svg";
import DE from "../images/flags/DE.svg";
import HK from "../images/flags/HK.svg";
import IN from "../images/flags/IN.svg";
import ID from "../images/flags/ID.svg";
import IT from "../images/flags/IT.svg";
import JP from "../images/flags/JP.svg";
import KR from "../images/flags/KR.svg";
import MY from "../images/flags/MY.svg";
import MX from "../images/flags/MX.svg";
import NL from "../images/flags/NL.svg";
import NZ from "../images/flags/NZ.svg";
import NO from "../images/flags/NO.svg";
import CN from "../images/flags/CN.svg";
import PL from "../images/flags/PL.svg";
import PH from "../images/flags/PH.svg";
import RU from "../images/flags/RU.svg";
import ZA from "../images/flags/ZA.svg";
import ES from "../images/flags/ES.svg";
import SE from "../images/flags/SE.svg";
import CH from "../images/flags/CH.svg";
import TW from "../images/flags/TW.svg";
import TR from "../images/flags/TR.svg";
import GB from "../images/flags/GB.svg";
import US from "../images/flags/US.svg";

export const MARKETLIST: ListItem[] = [
  {
    description: "Argentina",
    label: "Argentina - Spanish",
    key: "es-AR",
    iconPath: AR,
  },
  {
    description: "Australia",
    label: "Australia - English",
    key: "en-AU",
    iconPath: AU,
  },
  {
    description: "Austria",
    label: "Austria - German",
    key: "de-AT",
    iconPath: AT,
  },
  {
    description: "Belgium",
    label: "Belgium - Dutch",
    key: "nl-BE",
    iconPath: BE,
  },
  {
    description: "Belgium",
    label: "Belgium - French",
    key: "fr-BE",
    iconPath: BE,
  },
  {
    description: "Brazil",
    label: "Brazil - Portuguese",
    key: "pt-BR",
    iconPath: BR,
  },
  {
    description: "Canada",
    label: "Canada - English",
    key: "en-CA",
    iconPath: CA,
  },
  {
    description: "Canada",
    label: "Canada - French",
    key: "fr-CA",
    iconPath: CA,
  },
  {
    description: "Chile",
    label: "Chile - Spanish",
    key: "es-CL",
    iconPath: CL,
  },
  {
    description: "Denmark",
    label: "Denmark - Danish",
    key: "da-DK",
    iconPath: DK,
  },
  {
    description: "Finland",
    label: "Finland - Finnish",
    key: "fi-FI",
    iconPath: FI,
  },
  {
    description: "France",
    label: "France - French",
    key: "fr-FR",
    iconPath: FR,
  },
  {
    description: "Germany",
    label: "Germany - German",
    key: "de-DE",
    iconPath: DE,
  },
  {
    description: "Hong Kong SAR",
    label: "Hong Kong SAR - Traditional Chinese",
    key: "zh-HK",
    iconPath: HK,
  },
  {
    description: "India",
    label: "India - English",
    key: "en-IN",
    iconPath: IN,
  },
  {
    description: "Indonesia",
    label: "Indonesia - English",
    key: "en-ID",
    iconPath: ID,
  },
  {
    description: "Italy",
    label: "Italy - Italian",
    key: "it-IT",
    iconPath: IT,
  },
  {
    description: "Japan",
    label: "Japan - Japanese",
    key: "ja-JP",
    iconPath: JP,
  },
  { description: "Korea", label: "Korea - Korean", key: "ko-KR", iconPath: KR },
  {
    description: "Malaysia",
    label: "Malaysia - English",
    key: "en-MY",
    iconPath: MY,
  },
  {
    description: "Mexico",
    label: "Mexico - Spanish",
    key: "es-MX",
    iconPath: MX,
  },
  {
    description: "Netherlands",
    label: "Netherlands - Dutch",
    key: "nl-NL",
    iconPath: NL,
  },
  {
    description: "New Zealand",
    label: "New Zealand - English",
    key: "en-NZ",
    iconPath: NZ,
  },
  {
    description: "Norway",
    label: "Norway - Norwegian",
    key: "no-NO",
    iconPath: NO,
  },
  {
    description: "People's republic of China",
    label: "People's republic of China - Chinese",
    key: "zh-CN",
    iconPath: CN,
  },
  {
    description: "Poland",
    label: "Poland - Polish",
    key: "pl-PL",
    iconPath: PL,
  },
  {
    description: "Republic of the Philippines",
    label: "Republic of the Philippines - English",
    key: "en-PH",
    iconPath: PH,
  },
  {
    description: "Russia",
    label: "Russia - Russian",
    key: "ru-RU",
    iconPath: RU,
  },
  {
    description: "South Africa",
    label: "South Africa - English",
    key: "en-ZA",
    iconPath: ZA,
  },
  {
    description: "Spain",
    label: "Spain - Spanish",
    key: "es-ES",
    iconPath: ES,
  },
  {
    description: "Sweden",
    label: "Sweden - Swedish",
    key: "sv-SE",
    iconPath: SE,
  },
  {
    description: "Switzerland",
    label: "Switzerland - French",
    key: "fr-CH",
    iconPath: CH,
  },
  {
    description: "Switzerland",
    label: "Switzerland - German",
    key: "de-CH",
    iconPath: CH,
  },
  {
    description: "Taiwan",
    label: "Taiwan - Traditional Chinese",
    key: "zh-TW",
    iconPath: TW,
  },
  {
    description: "Turkey",
    label: "Turkey - Turkish",
    key: "tr-TR",
    iconPath: TR,
  },
  {
    description: "United Kingdom",
    label: "United Kingdom - English",
    key: "en-GB",
    iconPath: GB,
  },
  {
    description: "United States",
    label: "United States - English",
    key: "en-US",
    iconPath: US,
  },
  {
    description: "United States",
    label: "United States - Spanish",
    key: "es-US",
    iconPath: US,
  },
];
