import { Outlet } from "react-router-dom";
import { NavItem } from "../../types/platform/NavItem";
import { Footer } from "./Footer";
import { NavBar } from "./navigation/NavBar";
import { ProfileContext } from "../../util/ProfileContext";
import { useContext } from "react";

export const PrimaryNavItems: NavItem[] = [
  { path: "/", label: "Home", end: true },
  { path: "/pricing", label: "Pricing", end: true },
  { path: "/faq", label: "FAQ", end: true },
  { path: "/content-deliverers", label: "For Content Deliverers", end: true },
];

export const LandingLayout = (): JSX.Element => {
  const { profile } = useContext(ProfileContext);
  const navItems = PrimaryNavItems;

  // make sure the nav bar has the dashboard link if the user is authenticated
  if (navItems.some(item => item.path === "/dashboard")) {
    navItems.splice(
      navItems.findIndex(item => item.path === "/dashboard"),
      1,
    );
  }

  if (profile) {
    navItems.push({
      path: "/dashboard",
      label: "My Dashboard",
    });
  }

  return (
    <>
      <NavBar navItems={navItems} />
      <Outlet />
      <Footer />
    </>
  );
};
