import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import Onboarder from "../platform/onboarding/Onboarder";
import { NavBar } from "./navigation/NavBar";
import { NavItem } from "../../types/platform/NavItem";
import { UpSellContainer } from "../UpSellContainer";
import imgLessonPlans from "../../images/undraw_adventure_map_hnin.svg";
import imgAssessments from "../../images/undraw_learning_re_32qv.svg";
import imgComments from "../../images/undraw_personal_opinions_re_qw29.svg";
import imgUnits from "../../images/undraw_schedule_re_2vro.svg";

export const AppLayout = (): JSX.Element => {
  const navItems: NavItem[] = [
    {
      flyout: {
        id: "build",
        footerText: "Do more in the Studio",
        footerPath: "/studio",
        navItems: [
          {
            path: "/lessons",
            imageSrc: imgLessonPlans,
            label: "Plan Lessons",
            description:
              "Plan and manage your Lessons, Outcomes, Quiz Questions and Pedagogy",
          },
          {
            path: "/assessments",
            imageSrc: imgAssessments,
            label: "Build Assessments",
            description:
              "Construct rich assessments with robust rubric driven marking",
          },
          {
            path: "/units",
            imageSrc: imgUnits,
            label: "Structure Units of Work",
            description:
              "Build whole units of work with integrated lessons, assessments and outcomes",
          },
          {
            path: "/comments",
            imageSrc: imgComments,
            label: "Give Feedback",
            description:
              "Give personalised Feedback with comments aligned to your outcomes and expectations",
          },
        ],
      },
      label: "Build and Plan",
    },
    { path: "/studio", label: "Studio" },
    { path: "/uploads", label: "Uploads" },
  ];

  return (
    <>
      <Onboarder />
      <NavBar navItems={navItems} />
      <UpSellContainer />
      <Outlet />
      <Footer />
    </>
  );
};
