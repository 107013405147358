import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  DocumentIcon,
  VideoCameraIcon,
  PhotoIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../badges/Badge";
import { ProgressBar } from "../platform/fields/ProgressBar";
import { UploadIndex } from "../../types/Upload";
import { getUploadThumbnails } from "../../util/ApiIntegration";
import { Loading } from "../platform/Loading";
import { useQuery } from "@tanstack/react-query";

interface UploadCardProperties {
  upload: UploadIndex;
  linkTitle?: boolean;
}

function useThumbnail(upload: UploadIndex) {
  return useQuery({
    queryKey: ["thumbnail", upload.id],
    queryFn: async () => {
      if (!upload.id) return;
      const thumbnails = await getUploadThumbnails(upload.id, 1);
      return thumbnails[0];
    },
    enabled: !!upload.id && upload.processed,
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: 1000 * 60 * 60,
  });
}

function formatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}s `;
  }
  return `${remainingSeconds}s `;
}

const FileIcon: React.FC<{ fileExtension: string }> = React.memo(
  ({ fileExtension }) => {
    switch (fileExtension?.toUpperCase()) {
      case "PDF": {
        return <DocumentIcon className="h-12 w-12 text-gray-400" />;
      }
      case "MP4":
      case "MOV": {
        return <VideoCameraIcon className="h-12 w-12 text-gray-400" />;
      }
      case "JPG":
      case "PNG": {
        return <PhotoIcon className="h-12 w-12 text-gray-400" />;
      }
      default: {
        return <DocumentIcon className="h-12 w-12 text-gray-400" />;
      }
    }
  },
);

const ThumbnailImage: React.FC<{ src: string; alt: string }> = React.memo(
  ({ src, alt }) => (
    <img
      src={src}
      alt={alt}
      className="w-full h-full object-cover"
      loading="lazy"
    />
  ),
);

export function UploadCard({
  upload,
  linkTitle,
}: UploadCardProperties): JSX.Element {
  const { data: thumbnail, isLoading: isThumbnailLoading } =
    useThumbnail(upload);
  const [isVisible, setIsVisible] = useState(false);
  const cardReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 },
    );

    if (cardReference.current) {
      observer.observe(cardReference.current);
    }

    return () => {
      if (cardReference.current) {
        observer.unobserve(cardReference.current);
      }
    };
  }, []);

  const sizeInMB = ((upload.size || 0) / (1024 * 1024)).toFixed(2);

  const duration =
    upload.durationSeconds && upload.durationSeconds > 0
      ? formatDuration(upload.durationSeconds)
      : undefined;

  return (
    <div
      ref={cardReference}
      className="bg-white rounded-xl shadow-md overflow-hidden mb-4"
    >
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <div className="h-48 w-full md:w-48 bg-gray-200 flex items-center justify-center">
            {upload.processed ? (
              isThumbnailLoading ? (
                <Loading />
              ) : thumbnail && isVisible ? (
                <ThumbnailImage src={thumbnail} alt={upload.title || ""} />
              ) : (
                <FileIcon fileExtension={upload.fileExtension || ""} />
              )
            ) : upload.isBroken ? (
              <div className="text-center">
                <ExclamationTriangleIcon className="h-12 w-12 text-coachPink mx-auto" />
                <p className="text-sm text-coachPink mt-2">Error</p>
              </div>
            ) : (
              <div className="text-center">
                <DocumentIcon className="h-12 w-12 text-gray-400 mx-auto" />
                <p className="text-sm text-gray-500 mt-2">Processing...</p>
              </div>
            )}
          </div>
        </div>
        <div className="p-6 flex-grow">
          <div className="flex items-baseline mb-2">
            {upload.enabled && <Badge text="Enabled" className="mr-2" />}
            {upload.isbn && <Badge text={upload.isbn} />}
            {upload.doi && <Badge text={upload.doi} />}
            <div className="ml-2 text-gray-500 text-xs uppercase">
              {upload.fileExtension} • {sizeInMB} MB{" "}
            </div>
            {duration && (
              <div className="text-gray-500 text-xs"> • {duration} </div>
            )}
            <span>
              <div className="text-gray-500 text-xs">
                {" "}
                • {upload.slices} Slices
              </div>
            </span>
          </div>
          {linkTitle ? (
            <Link
              to={`/uploads/editor/${upload.id}`}
              className="block text-xl font-bold text-black hover:underline mb-2"
            >
              {upload.title || "No Title"}
            </Link>
          ) : (
            <p
              className="block text-xl font-bold text-black cursor-not-allowed mb-2"
              title="Not ready yet!"
            >
              {upload.title || "No Title"}
            </p>
          )}
          {upload.reference && (
            <p className="text-gray-500 text-xs">{upload.reference}</p>
          )}
          <p className="text-sm mt-2 mb-2">
            {upload.description && upload.description.length > 220
              ? `${upload.description.slice(0, 220)}...`
              : upload.description || ""}
          </p>

          {!upload.processed && (
            <div className="mt-4">
              <p className="text-sm mb-2">{upload.message}</p>
              {!upload.isBroken && (
                <ProgressBar
                  progress={upload.sliceIndex || 0}
                  max={upload.slices || 100}
                  paused={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
