import React, { useContext, useCallback, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Disclosure, Popover } from "@headlessui/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import logoDark from "../../../images/branding/teachology-horizontal-logo.svg";
import { ProfileMenu } from "./ProfileMenu";
import { NavItem } from "../../../types/platform/NavItem";
import { Button } from "../../platform/fields/Button";
import { ProfileContext } from "../../../util/ProfileContext";
import { Badge } from "../../badges/Badge";

interface NavBarProperties {
  navItems: NavItem[];
}

const defaultLinkStyle =
  "bg-opacity-0 text-center text-md border-toolkitGrey hover:border-toolkitTurquoise border-b-2 font-medium px-1 py-1 mb-1";
const mobileLinkStyle =
  "bg-opacity-0 text-md block font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10";

const NavLinkItem: React.FC<{
  item: NavItem;
  onClick?: () => void;
  className: string;
}> = React.memo(({ item, onClick, className }) => (
  <NavLink
    to={item.path || ""}
    onClick={onClick}
    className={({ isActive }) =>
      clsx(
        className,
        isActive ? "border-toolkitTurquoise text-toolkitTurquoise" : "",
      )
    }
  >
    {item.label}
    {item.new && (
      <Badge
        text="New"
        className="ml-1 mb-1"
        backgroundColor="bg-green-500"
        foregroundColor="text-white"
      />
    )}
  </NavLink>
));
NavLinkItem.displayName = "NavLinkItem";

const FlyoutItem: React.FC<{
  item: NavItem;
  onClose: () => void;
  isMobile: boolean;
}> = React.memo(({ item, onClose, isMobile }) => {
  const location = useLocation();
  const isChildActive = item.flyout?.navItems.some(
    flyoutItem =>
      flyoutItem.path && location.pathname.startsWith(flyoutItem.path),
  );

  if (isMobile) {
    return (
      <div>
        <p
          className={clsx(
            mobileLinkStyle,
            isChildActive ? "text-toolkitTurquoise font-bold" : "",
          )}
        >
          {item.label}
        </p>
        {item.flyout?.navItems.map(flyoutItem => (
          <NavLinkItem
            key={flyoutItem.label}
            item={flyoutItem}
            onClick={onClose}
            className={clsx(mobileLinkStyle, "pl-8")}
          />
        ))}
      </div>
    );
  }

  return (
    <Popover className="relative">
      {({ open, close: closePopover }) => (
        <>
          <Popover.Button
            className={clsx(
              defaultLinkStyle,
              "group inline-flex items-center",
              isChildActive || open
                ? "border-toolkitTurquoise text-toolkitTurquoise"
                : "",
            )}
          >
            <span>{item.label}</span>
            <ChevronDownIcon
              className={clsx(
                "ml-2 h-5 w-5 transition-transform",
                open ? "rotate-180 text-toolkitTurquoise" : "text-toolkitBlack",
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Popover.Panel className="absolute z-20 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                {item.flyout?.navItems.map(flyoutItem => (
                  <NavLink
                    end
                    to={flyoutItem.path || ""}
                    onClick={() => {
                      onClose();
                      closePopover();
                    }}
                    key={flyoutItem.label}
                    className={({ isActive }) =>
                      clsx(
                        "-m-3 p-5 cursor-pointer flex items-start rounded-lg transition ease-in-out duration-150",
                        isActive
                          ? "bg-toolkitTurquoise bg-opacity-10"
                          : "hover:bg-gray-100",
                      )
                    }
                  >
                    <img
                      className="w-16 -ml-4 -mr-1 -mt-1 float-left"
                      src={flyoutItem.imageSrc}
                      alt={flyoutItem.label}
                    />
                    <div className="ml-4">
                      <p
                        className={clsx(
                          "font-medium",
                          location.pathname.startsWith(flyoutItem.path || "")
                            ? "text-toolkitTurquoise"
                            : "",
                        )}
                      >
                        {flyoutItem.label}
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        {flyoutItem.description}
                      </p>
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0">
                <div className="flow-root w-full">
                  <NavLink
                    end
                    onClick={() => {
                      onClose();
                      closePopover();
                    }}
                    to={item.flyout?.footerPath || ""}
                    className="-m-3 p-3 flex items-center rounded-md font-medium hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <ArrowRightIcon
                      className="flex-shrink-0 h-6 w-5 text-gray-600"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{item.flyout?.footerText}</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
});
FlyoutItem.displayName = "FlyoutItem";

export const NavBar: React.FC<NavBarProperties> = React.memo(({ navItems }) => {
  const { profile, isLoading, refreshProfile } = useContext(ProfileContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard" && !profile) {
      refreshProfile();
    }
  }, [location.pathname, profile, refreshProfile]);

  const renderNavItems = useCallback(
    (isMobile: boolean, close: () => void) =>
      navItems.map(item => {
        if (item.path) {
          return (
            <NavLinkItem
              key={item.label}
              item={item}
              onClick={close}
              className={isMobile ? mobileLinkStyle : defaultLinkStyle}
            />
          );
        }
        if (item.flyout) {
          return (
            <FlyoutItem
              key={item.label}
              item={item}
              onClose={close}
              isMobile={isMobile}
            />
          );
        }
        return <div key={item.label}>Invalid Nav Item</div>;
      }),
    [navItems],
  );

  return (
    <Disclosure as="nav" className="">
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto xs:px-4 sm:px-8">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-6">
                  <Link data-id="nav-logo-home" to="/" onClick={() => close}>
                    <img
                      className="block md:hidden xs:h-12 sm:h-14 w-auto"
                      src={logoDark}
                      alt="teachology.ai"
                    />
                    <img
                      className="hidden md:block xs:h-12 sm:h-14 w-auto"
                      src={logoDark}
                      alt="teachology.ai"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex">
                <div className="hidden md:block">
                  <div className="flex space-x-4 mr-6">
                    {renderNavItems(false, close)}
                  </div>
                </div>
                {profile ? (
                  <ProfileMenu
                    isDropDown={true}
                    profile={profile}
                    onClose={close}
                  />
                ) : (
                  <Button
                    to="/login"
                    className="text-center"
                    isLoading={isLoading}
                    loadingColor="white"
                    variant="toolkitBlue"
                    onClick={close}
                  >
                    {isLoading ? "Connecting..." : "Login / Register"}
                  </Button>
                )}
              </div>
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-toolkitTurquoise hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-toolkitTurquoise">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {renderNavItems(true, close)}
              {!profile && (
                <Link
                  to="/login"
                  className="cursor-pointer text-center ml-6 xs:ml-0 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white hover:bg-indigo-50"
                  onClick={() => close}
                >
                  Login / Register
                </Link>
              )}
            </div>
            {profile && (
              <ProfileMenu
                isDropDown={false}
                profile={profile}
                onClose={close}
              />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
});
NavBar.displayName = "NavBar";
