import {
  Transition,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { useState, useEffect, Fragment, useContext } from "react";
import { Profile } from "../../../types/platform/Profile";
import { OnboardingForm } from "./OnboardingForm";
import ReactGA from "react-ga4";
import { ProfileContext } from "../../../util/ProfileContext";

export default function Onboarder(): JSX.Element {
  const [open, setOpen] = useState(false);
  const { profile, updateProfile } = useContext(ProfileContext);

  useEffect(() => {
    // check if the user needs to be onboarded
    // this is only set by login if the user needs it
    if (
      profile &&
      profile.id &&
      (profile.onboarded === undefined || profile.onboarded === false)
    ) {
      setOpen(true);
    }
  }, [profile]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          // swallow the close event
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white p-4">
                  <div className="mt-3 sm:mt-0">
                    <DialogTitle
                      as="h2"
                      className="text-3xl mb-4 w-full p-2 text-center"
                    >
                      🎉 Welcome!
                      <br />
                      <span className="text-2xl">
                        Lets finish setting up your account...
                      </span>
                    </DialogTitle>
                  </div>
                  {profile && (
                    <OnboardingForm
                      profile={profile}
                      onboardedProfile={(updatedProfile: Profile) => {
                        if (updatedProfile) {
                          ReactGA.event({
                            category: "account",
                            action: "onboarded",
                            label: "onboarded",
                          });
                          updateProfile(updatedProfile);
                          setOpen(false);
                        }
                      }}
                    ></OnboardingForm>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
