import React, { createContext, useState, useCallback, useEffect } from "react";
import { Limit, Profile } from "../types/platform/Profile";
import { useMsal } from "@azure/msal-react";
import { getLimits, getProfile } from "./ApiIntegration";
import { appInsights } from "./AppInsights";
import ReactGA from "react-ga4";
import { InteractionStatus } from "@azure/msal-browser";
import { identifyUserInClarity, setCustomTagInClarity } from "./Clarity";

type ProfileContextType = {
  profile: Profile | undefined;
  updateProfile: (profile: Partial<Profile>) => void;
  hasSubscription: boolean;
  platformLimit?: Limit;
  updatePlatformLimit: () => void;
  hitPlatformLimit: boolean;
  logout: () => void;
  refreshProfile: () => void;
  isLoading: boolean;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  updateProfile: () => {},
  hasSubscription: false,
  platformLimit: undefined,
  updatePlatformLimit: () => {},
  hitPlatformLimit: false,
  logout: () => {},
  refreshProfile: () => {},
  isLoading: false,
});

export const ProfileProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { inProgress, accounts, instance } = useMsal();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [platformLimit, setPlatformLimit] = useState<Limit>();
  const [hitPlatformLimit, setHitPlatformLimit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updatePlatformLimit = useCallback(() => {
    if (inProgress !== InteractionStatus.None) return;
    if (!instance) return;
    getLimits("platform").then(limits => {
      const limit = limits[0];
      if (!limit) {
        setPlatformLimit(undefined);
        return;
      }
      const rightNow = new Date();
      const limitExpiration = new Date(limit.resetTimeUtc);
      // if the limit has expired, set it to undefined
      if (rightNow > limitExpiration) {
        setPlatformLimit(undefined);
        setHitPlatformLimit(false);
        return;
      }
      if (limit.consumed >= limit.max) {
        setHitPlatformLimit(true);
      } else {
        setHitPlatformLimit(false);
      }
      // if the limit is still valid, set it
      setPlatformLimit({ ...limit });
    });
  }, [profile, inProgress, instance]);

  const refreshProfile = useCallback(() => {
    if (inProgress !== InteractionStatus.None) return;
    if (!instance) return;
    if (!accounts || accounts.length === 0) return;

    setIsLoading(true);

    getProfile()
      .then(latestProfile => {
        if (latestProfile && latestProfile.id) {
          // patch google analytics user id
          ReactGA.set({ userId: latestProfile.id });
          // patch app insights user id
          appInsights.setAuthenticatedUserContext(latestProfile.id);

          // New: Identify user in Clarity
          identifyUserInClarity(latestProfile.id);

          // Set custom tags in Clarity
          setCustomTagInClarity("email", latestProfile.email || "");
          setCustomTagInClarity(
            "subscription",
            latestProfile.subscription || "",
          );
          setCustomTagInClarity(
            "subscriptionEnd",
            latestProfile.subscriptionEnd
              ? latestProfile.subscriptionEnd.toString()
              : "",
          );
          setCustomTagInClarity("market", latestProfile.market || "");

          // track the login
          ReactGA.event({
            category: "account",
            action: "login",
            label: "login",
          });
          setProfile(latestProfile);
          updatePlatformLimit();
        }
      })
      .catch(error => {
        console.log("Unable to set the current user profile", {
          error,
          profile,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [inProgress, accounts, instance]);

  const logout = useCallback(() => {
    setProfile(undefined);
    setPlatformLimit(undefined);
    setHitPlatformLimit(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    refreshProfile();
  }, [inProgress, accounts, instance]);

  const updateProfile = useCallback(
    (updatedProfile: Partial<Profile>) => {
      if (profile) {
        setProfile(currentProfile => ({
          ...currentProfile,
          ...updatedProfile,
        }));
      } else {
        setProfile(updatedProfile as Profile);
      }
    },
    [profile],
  );

  const validSubscriptionTypes = new Set([
    "pro",
    "starter",
    "pilot",
    "institution",
  ]);
  const hasSubscription = profile?.subscriptionEnd
    ? validSubscriptionTypes.has(profile.subscription ?? "") &&
      new Date(profile.subscriptionEnd).getTime() >= Date.now()
    : false;

  return (
    <ProfileContext.Provider
      value={{
        profile,
        updateProfile,
        hasSubscription,
        platformLimit,
        updatePlatformLimit,
        hitPlatformLimit,
        logout,
        refreshProfile,
        isLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
