import React, { useState, useRef, useEffect } from "react";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import { ListItem } from "../../../types/platform/ListItem";

interface DropdownProperties {
  name: string;
  label?: string;
  helper?: string;
  options: ListItem[];
  value: string;
  disabled?: boolean;
  onChange?: (key: string) => void;
}

export const Dropdown: React.FC<DropdownProperties> = ({
  name,
  label,
  helper,
  options,
  value,
  disabled = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownReference = useRef<HTMLDivElement>(null);
  const buttonReference = useRef<HTMLButtonElement>(null);
  const selectedOption = options.find(option => option.key === value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownReference.current &&
        !dropdownReference.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (key: string) => {
    onChange?.(key);
    setIsOpen(false);
    buttonReference.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      buttonReference.current?.focus();
    }
  };

  return (
    <div className="relative" ref={dropdownReference}>
      {label && (
        <label htmlFor={name} className="block font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div>
        <button
          ref={buttonReference}
          type="button"
          className={`
            bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left 
            focus:outline-none focus:ring-2 focus:ring-toolkitTurquoise focus:border-toolkitTurquoise 
            sm:text-sm transition duration-150 ease-in-out
            ${disabled ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer hover:bg-gray-50"} 
            ${isOpen ? "ring-2 ring-toolkitTurquoise border-toolkitTurquoise" : "border-gray-300"}
          `}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby={label}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        >
          <span className="flex items-center">
            {selectedOption?.iconPath && (
              <img
                src={selectedOption.iconPath}
                alt=""
                className="flex-shrink-0 h-5 w-5 rounded-md mr-2"
              />
            )}
            <span className="block truncate">
              {selectedOption?.label || "Please Select"}
            </span>
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className={`h-5 w-5 ${isOpen ? "text-toolkitTurquoise" : "text-gray-400"}`}
              aria-hidden="true"
            />
          </span>
        </button>

        {isOpen && (
          <ul
            className="
              absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base 
              ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm
            "
            tabIndex={-1}
            role="listbox"
            aria-labelledby={label}
            aria-activedescendant={selectedOption?.key}
            onKeyDown={handleKeyDown}
          >
            {options.map(option => (
              <li
                key={option.key}
                className={`
                  cursor-pointer select-none relative py-2 pl-3 pr-9
                  ${
                    option.key === value
                      ? "text-white bg-toolkitTurquoise"
                      : "text-gray-900 hover:bg-gray-100"
                  }
                `}
                id={option.key}
                role="option"
                aria-selected={option.key === value}
                onClick={() => handleSelect(option.key)}
              >
                <div className="flex items-center">
                  {option.iconPath && (
                    <img
                      src={option.iconPath}
                      alt=""
                      className="flex-shrink-0 h-5 w-5 rounded-md mr-2"
                    />
                  )}
                  <span
                    className={`block truncate ${option.key === value ? "font-semibold" : "font-normal"}`}
                  >
                    {option.label}
                  </span>
                </div>

                {option.key === value && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}

        {helper && (
          <p id={`${name}-helper`} className="mt-2 text-sm text-gray-600">
            {helper}
          </p>
        )}
      </div>
    </div>
  );
};
