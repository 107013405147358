export function formatBytes(bytes: number, decimals: number): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const index = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / Math.pow(k, index)).toFixed(dm))} ${
    sizes[index]
  }`;
}

export const isEmail = (email: string): boolean => {
  return /[\w.-]+@[\w.-]+\.[\w.-]+/.test(email);
};

export function useQueryParameters() {
  return new URLSearchParams(window.location.search);
}

export async function sleep(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export const formatDuration = (ms: number) => {
  if (ms < 0) ms = -ms;
  const time = {
    day: Math.floor(ms / 86_400_000),
    hour: Math.floor(ms / 3_600_000) % 24,
    minute: Math.floor(ms / 60_000) % 60,
    second: Math.floor(ms / 1000) % 60,
    millisecond: Math.floor(ms) % 1000,
  };
  return Object.entries(time)
    .filter(value => value[1] !== 0)
    .map(value => value[1] + " " + (value[1] === 1 ? value[0] : value[0] + "s"))
    .join(", ");
};

export const getDateTimeAsString = (dateTime?: Date) => {
  if (!dateTime) {
    dateTime = new Date();
  }

  return `${dateTime.getFullYear()}-${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")}_${dateTime
    .getHours()
    .toString()
    .padStart(2, "0")}-${dateTime.getMinutes().toString().padStart(2, "0")}`;
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to convert file to base64"));
      }
    });
    reader.addEventListener("error", error => reject(error));
  });
};
